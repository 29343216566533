import React from "react";

import LayoutHome from "../components/layout-home";
import SEO from "../components/seo";
import catAndHumanIllustration from "../images/cat-and-human-illustration.svg";

function IndexPage() {
  return (
    <LayoutHome>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />

      <div className="home-hero">
        <div className="home-hero-content ">
          <p className="text-4xl text-center max-w-lg mb-8">
            Exceptional Software delivered with <strong>Dedication</strong> and{" "}
            <strong>Diligence</strong>
          </p>
          <div className="home-buttons text-center max-w-lg">
            <button className="schedule mr-2 font-bold border border-indigo-500 bg-indigo-500 text-white rounded-md px-6 py-3 m-2 transition duration-500 ease select-none hover:bg-indigo-600 focus:outline-none focus:shadow-outline">
              Schedule a Free Consultation
            </button>
            <button className="home-secondary border font-bold border-white text-white rounded-md px-6 py-3 transition duration-500 ease select-none hover:bg-gray-500 focus:outline-none focus:shadow-outline">
              What We Do
            </button>
          </div>
        </div>
      </div>

      <div className="home-A flex-1 w-full max-w-4xl px-4 py-8 mx-auto md:px-8 md:py-16">
        <section className="text-center">
          <p className="text-2xl block w-2/3 mx-auto mb-8">
            UPHAWK helps you solve your most complex problems through custom and
            innovative software solutions, so you can kick back with the cat.
          </p>
          <img
            alt="Cat and human sitting on a couch"
            className="block w-1/3 mx-auto mb-8"
            src={catAndHumanIllustration}
          />
          <p className="text-left text-lg w-3/4 mx-auto mb-8">
            With talent across the United States, our onshore team is made up of
            coders, scientists, artists, entrepreneurs and engineers. Our
            diverse skillset is as diverse as our favorite cup of coffee or tea,
            but we do have some things in common.
          </p>
          <p className="text-left text-lg w-3/4 mx-auto mb-8">
            We are passionate about the things we build and dedicated to our
            craft. We enjoy finding solutions to difficult problems and deriving
            insight from complex data. We&apos;re enthusiastic about our clients
            success and approach each task with diligence and careful execution.
            And we&apos;re excited to help you accomplish your next business
            goals.
          </p>
          <p className="text-left text-lg w-3/4 mx-auto mb-8">
            Whether you need an enterprise web application, an AI-powered data
            lake, or a full-scale digital transformation strategy, uphawk
            systems has you covered.
          </p>

          <h2 className="inline-block p-3 mb-4 text-2xl font-bold bg-yellow-300">
            Early Access Customers get 20% off during Q1 2021!
          </h2>

          <p className="leading-loose">
            E-mail us to get started:{" "}
            <a
              className="font-bold text-gray-900"
              href="mailto:hello@uphawk.systems"
              target="_blank"
              rel="noopener noreferrer"
            >
              hello@uphawk.systems
            </a>
          </p>
        </section>
      </div>
    </LayoutHome>
  );
}

export default IndexPage;
