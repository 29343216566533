import PropTypes from "prop-types";
import React from "react";

import Header from "./header";
import Footer from "./footer";

function LayoutHome({ children }) {
  return (
    <div id="home" className="flex flex-col min-h-screen text-gray-900">
      <Header />

      <main>{children}</main>
      <Footer />
    </div>
  );
}

LayoutHome.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutHome;
